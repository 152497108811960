<template>
  <main
    class="color-primary h-100 page_bg_grey blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700" v-if="isNew">
      {{ $t('Gifts - New') }}
    </p>
    <p class="main-title weight-700" v-else>
      {{ $t('Gifts') }}
    </p>
    <section class="content-margin">
      <div class="d-flex mb-10 flex-wrap" v-if="!isNew">
        <div class="px-7 filter-form w-100 mb-7">
          <label>{{ $t('Gift list') }}</label>
          <vue-select
            :options="giftList"
            class="input cus-select"
            v-model="giftData"
            label="name"
            @input="changeGiftList"
          ></vue-select>
        </div>
      </div>

      <div class="d-flex flex-wrap">
        <div class="w-40 pt-10">
          <div class="d-flex flex-wrap">
            <div
              class="px-7 mb-5 validation-form-group"
              :class="{ 'form-group--error': $v.pageData.name.$error }"
            >
              <label for="">
                <span class="color-red4">* </span
                >{{ $t('Name of gift') }}
              </label>
              <input
                type="text"
                class="input"
                v-model="pageData.name"
                @input="$v.pageData.name.$touch"
              />
              <div
                class="color-red1 fs12 valide-error-msg"
                v-if="!$v.pageData.name.required"
              >
                {{ $t('This is a required field.') }}
              </div>
            </div>
            <div class="px-7 point-form mb-5 validation-form-group"
              :class="{ 'form-group--error': $v.pageData.points_required.$error }"
            >
              <label for="">
                <span class="color-red4">* </span
                >{{ $t('Points to be utilized') }}
              </label>
              <input
                type="text"
                class="input"
                v-model="pageData.points_required"
                @keypress="isNumber($event)"
                @input="$v.pageData.points_required.$touch"
              />
              <div
                class="color-red1 fs12 valide-error-msg"
                v-if="!$v.pageData.points_required.required"
              >
                {{ $t('This is a required field.') }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center px-7 mt-15 mb-15">
            <v-checkbox
              color="#0D3856"
              class="cus-checkbox"
              v-model="activeGift"
              id="activeGift"
            ></v-checkbox>
            <label class="px-5" for="activeGift">{{
              $t('Active gift')
            }}</label>
          </div>
        </div>
        <div class="w-30 px-7 validation-form-group"
          :class="{ 'form-group--error': $v.pageData.description.$error }"
        >
          <label for="" class="fs14">
            <span class="color-red4">* </span
            >{{ $t('Details on waiting') }}
          </label>
          <textarea
            class="input h-100 p-2"
            v-model="pageData.description"
            @input="$v.pageData.description.$touch"
          ></textarea>
          <div
            class="color-red1 fs12 valide-error-msg"
            v-if="!$v.pageData.description.required"
          >
            {{ $t('This is a required field.') }}
          </div>
        </div>
        <div class="w-30 px-7 upload-block">
          <label for="" class="fs14">{{
            $t('Product image')
          }}</label>
          <ImgUpload v-model="imgUrl" />
        </div>
      </div>

      <div class="px-7 mt-20 d-flex">
        <button class="primary-btn btn-limit-width" @click="createUpdateData()">
          {{ $t('Save') }}
        </button>
        <button
          class="primary-btn btn-limit-width bg-yellow mx-7"
          @click="switchNew(false)"
          v-if="isNew"
        >
          {{ $t('Cancel') }}
        </button>
        <button
          class="primary-btn btn-limit-width bg-yellow mx-7"
          @click="switchNew(true)"
          v-else
        >
          {{ $t('New') }}
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import ImgUpload from '../../../components/ImgUpload';
import appConfig from '../../../appConfig';
export default {
  name: 'CPGiftPoints',
  mixins: [validationMixin],
  components: {
    ImgUpload,
  },
  data() {
    return {
      giftData: '',
      imgFile: '',
      isFileUpload: false,
      activeGift: false,
      pageData: '',
      isNew: false,
      currentPageData: '',
    };
  },
  validations: {
    pageData: {
      name: {
        required,
      },
      points_required: {
        required,
      },
      description: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      giftList: (state) => state.category_products.giftList,
    }),
    apiURL() {
      return appConfig.apiUrl;
    },
    imgUrl: {
      get: function () {
        this.isFileUpload = false;
        if (this.pageData.picture) {
          return this.apiURL + '/' + this.pageData.picture;
        } else {
          return false;
        }
      },
      set: function (value) {
        this.isFileUpload = true;
        this.imgFile = value;
        return value;
      },
    },
  },
  async created() {
    await this.getGiftList();
    if (this.giftList.length) {
      this.giftData = this.giftList[0];
      this.changeGiftList();
    }
  },
  methods: {
    ...mapActions('category_products', {
      getGiftList: 'getGiftList',
      updateGift: 'updateGift',
      createGift: 'createGift',
    }),
    createUpdateData() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      let formData = new FormData();
      formData.append('description', this.pageData.description);
      if (this.isFileUpload) {
        formData.append('image', this.imgFile);
      } else {
        formData.append('image', this.imgUrl);
      }
      formData.append('name', this.pageData.name);
      formData.append('points_required', this.pageData.points_required);
      if (this.activeGift) {
        formData.append('status', 1);
      } else {
        formData.append('status', 2);
      }

      if (this.isNew) {
        this.createGift({
          params: formData,
        }).then(async (res) => {
          if (res == 'success') {
            await this.getGiftList();
            const leng = this.giftList.length;
            this.giftData = this.giftList[leng - 1];
          }
          this.changeGiftList();
          this.isNew = false;
        });
      } else {
        const id = this.pageData.id;
        this.updateGift({
          id: id,
          params: formData,
        }).then(async (res) => {
          await this.getGiftList();
          this.giftData = this.giftList[0];
          this.changeGiftList();
        });
      }
    },
    setActiveCheckbox() {
      if (this.pageData.status == 1) {
        this.activeGift = true;
      } else {
        this.activeGift = false;
      }
    },
    changeGiftList() {
      this.pageData = JSON.parse(JSON.stringify(this.giftData));
      this.setActiveCheckbox();
    },
    switchNew(val) {
      this.isNew = val;
      if (val) {
        this.currentPageData = JSON.parse(JSON.stringify(this.pageData));
        this.pageData = {};
        this.activeGift = false;
      } else {
        this.pageData = this.currentPageData;
        this.changeGiftList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-form {
  max-width: 350px;
}
.point-form {
  width: 200px;
}

.upload-block {
  max-height: 300px;
  min-height: 100px;
}
@media screen and (max-width: 900px) {
  .w-40,
  .w-30 {
    width: 100% !important;
  }
  .upload-block {
    margin-top: 50px;
  }
}
</style>